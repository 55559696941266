import * as React from "react"
import Layout, { Section } from "../components/layout"
import { FadeInBottom } from "../components/FadeIn"
import HeaderImg from "../images/Index/images/header.svg"
import OfficeImg from "../images/Index/images/office-picture.jpg"
import BannerImg from "../images/Index/images/banner.jpg"
import SurgeryPicoSure from "../images/Index/images/surgery-picosure.png"
import SurgeryFlx from "../images/Index/images/surgery-flx.png"
import SurgeryUltraformer from "../images/Index/images/surgery-ultraformer.png"
import IntroductionImg from "../images/Index/images/introduction.jpg"
import OfficeBackgroundImg from "../images/Index/images/main-office-background.jpg"
import Logo from "../images/Index/images/logo.svg"
import Doctor01Image from "../images/Index/images/doctor-01.jpg"
import Doctor02Image from "../images/Index/images/doctor-02.jpg"
import Doctor03Image from "../images/Index/images/doctor-03.jpg"
import Env01Image from "../images/Index/images/env-01.jpg"
import Env02Image from "../images/Index/images/env-02.jpg"
import Env03Image from "../images/Index/images/env-03.jpg"
import Introduction01Img from "../images/Index/images/introduction-01.jpg"
import Introduction02Img from "../images/Index/images/introduction-02.jpg"
import Introduction03Img from "../images/Index/images/introduction-03.jpg"
import Introduction04Img from "../images/Index/images/introduction-04.jpg"
import SEO from "../components/seo"

const IndexPage = () => {
  React.useEffect(() => {
    window.location.href = "https://www.lotusclinic.com.tw/"
  })
  return <div />
}
/*
const IndexPage = () => (
  <Layout>
    <SEO title={"首頁"} />
    <section css={{ "@media(min-width: 768px)": { display: "none" } }}>
      <FadeInBottom>
        <div css={{ display: "flex", justifyContent: "center" }}>
          <img
            src={BannerImg}
            alt={"Elegance is the only beauty that never fades"}
          />
        </div>
      </FadeInBottom>
    </section>
    <Section
      css={{
        display: "none",
        "@media(min-width: 768px)": { display: "block" },
      }}
    >
      <img src={HeaderImg} />
    </Section>
    <section
      css={{
        display: "none",
        "@media(min-width: 768px)": { display: "block" },
      }}
    >
      <img src={OfficeImg} />
    </section>
    <Section>
      <div
        css={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          gap: 25,
          "@media(min-width: 768px)": {
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "start",
            gap: 35,
          },
        }}
      >
        <FadeInBottom threshold={0.3}>
          <div
            css={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              gap: 25,
            }}
          >
            <a css={{ display: "block" }} href={"/picosure"}>
              <img src={SurgeryPicoSure} alt={"皮秒雷射"} />
            </a>
            <h5>PicoSure蜂巢皮秒755雷射</h5>
          </div>
        </FadeInBottom>
        <FadeInBottom threshold={0.3}>
          <div
            css={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              gap: 25,
            }}
          >
            <a css={{ display: "block" }} href={"/thermage"}>
              <img src={SurgeryFlx} alt={"鳳凰電波"} />
            </a>
            <h5>Thermage® FLX鳳凰電波</h5>
          </div>
        </FadeInBottom>
        <FadeInBottom threshold={0.3}>
          <div
            css={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              gap: 25,
            }}
          >
            <a css={{ display: "block" }} href={"/ultraformer"}>
              <img src={SurgeryUltraformer} alt={"海芙音波拉提"} />
            </a>
            <h5>Ultraformer III海芙音波拉提</h5>
          </div>
        </FadeInBottom>
      </div>
    </Section>
    <section
      css={{
        display: "none",
        paddingLeft: "10vw",
        paddingBottom: 100,
        "@media(min-width: 768px)": {
          display: "block",
        },
      }}
    >
      <FadeInBottom>
        <div
          css={{
            display: "none",
            gridTemplateColumns: "80px 1fr",
            gridColumnGap: 15,
            "@media(min-width: 768px)": {
              display: "grid",
            },
          }}
        >
          <div
            css={{
              width: "100%",
              height: 1,
              backgroundColor: "#717171",

              marginTop: 20,
            }}
          />
          <div>
            <h2
              css={{
                fontFamily: "Times New Roman, Times, serif, Arial, sans-serif",
                marginBottom: 25,
              }}
            >
              Lotus ．Timeless Beauty
            </h2>
            <p>
              女人的美隨著年齡越顯煥發，每個年齡都是美麗的起點，
              <br />
              不同年齡自有相應的風格美感足以襯托。
            </p>
          </div>
        </div>
      </FadeInBottom>
      <FadeInBottom>
        <img src={Introduction01Img} alt={"introduction-image"} />
      </FadeInBottom>
      <FadeInBottom>
        <div
          css={{
            paddingRight: "10vw",
            display: "grid",
            paddingTop: 30,
            gridColumnGap: 25,
            gridAutoFlow: "column",
          }}
        >
          <img src={Introduction02Img} />
          <img src={Introduction03Img} />
          <img src={Introduction04Img} />
        </div>
      </FadeInBottom>
    </section>
    <Section
      css={{
        "@media(min-width: 768px)": {
          display: "none",
        },
      }}
    >
      <FadeInBottom>
        <img src={IntroductionImg} alt={"lotus timeless beauty"} css={{}} />
      </FadeInBottom>
    </Section>
    <section
      css={{
        backgroundImage: `url(${OfficeBackgroundImg})`,
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center top",
        backgroundSize: "cover",
        position: "relative",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        padding: "30px 0px",
        "&:before": {
          content: '" "',
          position: "absolute",
          left: 0,
          right: 0,
          top: 0,
          bottom: 0,
          opacity: 0.9,
          backgroundColor: "#fbf8f3",
        },
      }}
    >
      <FadeInBottom>
        <img
          src={Logo}
          alt={"logo"}
          css={{
            maxWidth: 258,
          }}
        />
      </FadeInBottom>
      <div
        css={{
          marginTop: 30,
          display: "grid",
          gridTemplateColumns: "25% 1fr 25%",
          gridColumnGap: 10,
          height: 200,
          zIndex: 1,
          width: "100%",
          "@media(min-width: 768px)": {
            height: 500,
          },
        }}
      >
        <div
          css={{
            backgroundImage: `url(${Doctor01Image})`,
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center center",
            backgroundSize: "cover",
          }}
        />
        <div
          css={{
            backgroundImage: `url(${Doctor02Image})`,
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center center",
            backgroundSize: "cover",
          }}
        />
        <div
          css={{
            backgroundImage: `url(${Doctor03Image})`,
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center center",
            backgroundSize: "cover",
          }}
        />
      </div>
      <div
        css={{
          display: "grid",
          gridTemplateColumns: "25% 1fr 25%",
          gridColumnGap: 10,
          marginTop: 15,
          height: 150,
          zIndex: 1,
          width: "100%",
          "@media(min-width: 768px)": {
            height: 400,
            gridTemplateColumns: "35% 1fr 35%",
          },
        }}
      >
        <div
          css={{
            backgroundImage: `url(${Env01Image})`,
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center center",
            backgroundSize: "cover",
          }}
        />
        <div
          css={{
            backgroundImage: `url(${Env02Image})`,
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center center",
            backgroundSize: "cover",
          }}
        />
        <div
          css={{
            backgroundImage: `url(${Env03Image})`,
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center center",
            backgroundSize: "cover",
          }}
        />
      </div>
    </section>
  </Layout>
)
*/

export default IndexPage
